<template>
  <div class="app-container">
    <div class="table-container">
      <el-table
        ref="multipleTable"
        :data="list"
        tooltip-effect="dark"
        v-loading.body="listLoading"
        element-loading-text="加载中"
        fit
        highlight-current-row
      >
        <el-table-column align="center" label="内容" prop="time"></el-table-column>
        <el-table-column align="center" label="内容" prop="context"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { deliveryGet } from '@/api/order/refund';
import cloneDeep from 'lodash/cloneDeep';
export default {
  components: {},
  data() {
    const initFilter = {
      name: '', // 参数名称
    };
    return {
      TOTOP: false,
      UP: false,
      initFilter,
      filter: cloneDeep(initFilter),
      list: [],
      listLoading: true,
      deliveryCompanyCode: '',
      deliveryNumber: '',
    };
  },
  computed: {
    // 过滤
    data() {
      const obj = {};
      obj.deliveryCompanyCode = this.$route.params.companyCode;
      obj.deliveryNumber = this.$route.params.deliveryNo;
      return obj;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.listLoading = true;
      deliveryGet(this.data)
        .then((response) => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
</style>
