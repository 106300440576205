import { withExtTenantIdRequest } from '@/utils/request';

// 退款订单列表
export function listMyPage(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/refundOrder/listMyPage',
    method: 'post',
    data
  });
}

// 退款订单导出
export function exportTable (data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/refundOrder/exportForMerchant`,
    method: 'post',
    responseType: 'arraybuffer',
    data,
    timeout: 30 * 1000
  });
}

// 获取退款详情
export function getById(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/refundOrder/get?id=${id}`,
    method: 'get'
  });
}

// 获取订单详情
export function getOrderById(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/order/getVO?id=${id}`,
    method: 'get'
  });
}

// 获取订单包裹信息
export function getDeliveryInfo(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/order/getDeliveries?id=${id}`,
    method: 'get'
  });
}

// 获取退款方式列表
export function fetchStatusOptions() {
  return withExtTenantIdRequest({
    url: '/common/api/dict/listByType?type=refund_status',
    method: 'get'
  });
}

// 计算订单最大可退金额
export function calculateMaxRefundMoney(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/orderPrice/calculateMaxRefundMoney',
    method: 'post',
    data
  });
}

// 退款申请
export function create(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/refundOrder/create',
    method: 'post',
    data
  });
}

// 买家发货
export function delivered(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/refundOrder/delivered',
    method: 'post',
    data
  });
}

// 获取退款物流状态
export function getDeliveryByRefundOrderId(id) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/refundOrder/getDeliveryByRefundOrderId?id=' + id, 
    method: 'get'
  });
}

// 撤销退款
export function buyerCancel(id) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/refundOrder/buyerCancel?id=${id}`,
    method: 'post'
  });
}
export function deliveryGet(id) {
  return withExtTenantIdRequest({
    url: '/chat-service/api/extWindow/getDelivery?id=' + id, 
    method: 'get'
  });
}

// 退款查询订单使用了返利支付，且返利存在在退款时间已失效情况
export function cancelCreditBackRemind(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/refundOrder/cancelCreditBackRemind',
    method: 'post',
    data
  });
}